import { getIntlProps } from '@moxy/next-intl';
import { GetServerSideProps } from 'next';
import { IndexPage } from '../src/containers/IndexPage';

// eslint-disable-next-line import/no-default-export
export default IndexPage;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const intlProps = await getIntlProps(ctx.locale);

  return {
    props: {
      ...intlProps
    }
  };
};
