import React, { useCallback } from 'react';
import { Button, Collapse, Nav, Navbar } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { GenericNavbarBrand } from './GenericNavbarBrand';

export const GenericNavbar: React.FC<{
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  additionalLeftNavItems?: JSX.Element | null;
  isOpen: boolean;
  className?: string;
  setIsOpen: (isOpen: boolean) => void;
  dark?: boolean;
}> = React.memo(
  ({ className, centerContent, rightContent, additionalLeftNavItems = null, isOpen, setIsOpen, dark }) => {
    const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
    return (
      <Navbar dark={dark} expand="lg" className={classNames(className, { active: isOpen })}>
        <Button
          onClick={toggle}
          color="transparent"
          className={classNames('d-block d-lg-none px-3 pr-5 mr-n5 py-5 my-n5', {
            'text-white': dark
          })}
          style={{ boxShadow: 'none' }}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>

        {centerContent || <GenericNavbarBrand dark={dark} />}
        <div />

        <Collapse isOpen={isOpen} navbar>
          {rightContent}
          <Nav navbar>{additionalLeftNavItems}</Nav>
        </Collapse>
      </Navbar>
    );
  }
);
