import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Container } from 'reactstrap';
import Link from 'next/link';
import style from './IndexDownloadNow.module.scss';
import BlackBg from '../../../public/statics/svgs/black-bg.svg';
import Walrus2 from '../../../public/statics/svgs/walrus-2.svg';

export const IndexDownloadNow: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.background}>
          <BlackBg />
        </div>
        <Container className="position-relative">
          <div className={style.header}>
            <h2 className="text-heading text-shadow-primary-on-black text-white">
              {intl.formatMessage({
                id: 'web.download-now.ready-to-feud',
                defaultMessage: 'Ready to expand imagination?'
              })}
            </h2>
            <h2 className="text-heading text-shadow-primary-on-black text-accent2">
              {intl.formatMessage({
                id: 'web.home.download-now.download-the-game',
                defaultMessage: 'Craft your own story now!'
              })}
            </h2>
          </div>
          <div className="mt-5 text-center">
            <Link legacyBehavior href="/create" passHref>
              <Button size="lg" color="primary" className="text-accent2 text-heading py-3 px-5 btn-mobile-border">
                {intl.formatMessage({
                  id: 'web.nav.download',
                  defaultMessage: 'Create the tale'
                })}
              </Button>
            </Link>
          </div>
        </Container>
        <div className={style.walrus}>
          <Walrus2 />
        </div>
      </div>
    </div>
  );
});
