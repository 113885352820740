import React from 'react';
import Link from 'next/link';
import { NavItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import style from './NavbarTestNowButton.module.scss';

export const NavbarTestNowButton: React.FC<{ dark?: boolean }> = ({ dark }) => (
  <NavItem>
    <Link href="/create" className={classNames('text-heading', style.link, { [style.dark]: dark })}>
      <strong>
        <FormattedMessage id="web.nav.download" defaultMessage="Create the tale" />
      </strong>
    </Link>
  </NavItem>
);
