import React, { useState } from 'react';
import { Nav } from 'reactstrap';
import { GenericNavbar } from './GenericNavbar';
import { NavbarTestNowButton } from './NavbarTestNowButton';

export const NavbarComponent: React.FC<{
  dark?: boolean;
}> = React.memo(({ dark }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <GenericNavbar
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dark={dark}
      rightContent={
        <Nav navbar className="order-lg-12 ml-auto ord">
          <NavbarTestNowButton dark={dark} />
        </Nav>
      }
    />
  );
});
