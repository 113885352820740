import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { Footer } from '../layouts/Footer';
import { IndexHostYourOwnGame } from './index/IndexHostYourOwnGame';
import { IndexDownloadNow } from './index/IndexDownloadNow';
import { IndexParagraphs } from './index/IndexParagraphs';
import { IndexNeverForget } from './index/IndexNeverForget';

const { pageTitle } = defineMessages({
  pageTitle: {
    id: 'web.main_title',
    defaultMessage: 'CustomTales: Your own unique tales for vivid imagineers'
  }
});

export const IndexPage: NextPage = React.memo(() => {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>{intl.formatMessage(pageTitle)}</title>
      </Head>
      <IndexHostYourOwnGame />
      <IndexParagraphs />
      <IndexNeverForget />
      <IndexDownloadNow />
      <Footer />
    </>
  );
});
