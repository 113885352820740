import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import style from './IndexParagraphs.module.scss';
import { nextAsset } from '../../components/nextAsset';
import WalrusEvenMe from '../../../public/statics/svgs/walrus-even-me.svg';

export const IndexParagraphs: React.FC = React.memo(() => {
  const intl = useIntl();
  const { locale } = useRouter();
  return (
    <div className={style.container}>
      <Container className="d-flex flex-column-reverse flex-lg-row overflow-hidden">
        <div className={style.sweatyFace}>
          <img src={nextAsset('images/design/sweaty-face.png')} className="img-fluid" alt="Sweaty Face" />
        </div>
        <div className={style.ovalSalmon}>
          {intl.formatMessage(
            {
              id: 'web.home.host-your-own-game.the-thrill.subtitle',
              defaultMessage:
                '<b>WHEN YOUR KID ASKS YOU FOR A NEW STORY...</b>{br}{br} No more worries, <b>CustomTales</b> allows you to create your own <b>personalised stories</b> for your kids. Make them part of it, base it on their recent experiences and immerse yourself together.'
            },
            {
              b: (children: React.ReactNode) => <span>{children}</span>,
              br: <br />
            }
          )}
        </div>
        <div className={style.tv}>
          <img src={nextAsset('images/design/tv.png')} className="img-fluid" alt="TV" />
        </div>
      </Container>
      <Container className="mt-5 d-flex flex-column">
        <div>
          <div className={style.middleTitle}>
            {intl.formatMessage(
              {
                id: 'web.home.host-your-own-game.excitement.title',
                defaultMessage:
                  'There’s nothing like the{br}<b>action</b> and <b>excitement</b> of{br}the custom <b>tailored</b> bedtime story!'
              },
              {
                b: (children: React.ReactNode) => <span>{children}</span>,
                br: <br />
              }
            )}
          </div>
        </div>
      </Container>
      <Container
        className={classNames(
          'd-flex flex-column-reverse flex-lg-row pb-5 mb-5 overflow-hidden',
          style.secondParagraph
        )}
      >
        <div className={style.walrus}>
          <WalrusEvenMe />
        </div>
        <div className={style.ovalBlue}>
          {intl.formatMessage(
            {
              id: 'web.home.host-your-own-game.excitement.subtitle',
              defaultMessage:
                '<b>CustomTales</b> will create <b>special</b> and <b>unique</b> bedtime stories, that will engage even the most <b>vivid imaginers</b>. Make sure that your loved little humans always have <b>new and exciting</b> story to hear from you. Story that they can relate to!'
            },
            {
              b: (children: React.ReactNode) => <span>{children}</span>
            }
          )}
        </div>
        <div className={style.face}>
          <img src={nextAsset(`images/design/face-${locale}.png`)} className="img-fluid" alt="Sweaty Face" />
        </div>
      </Container>
    </div>
  );
});
